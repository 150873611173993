import React  from 'react';
import { Redirect } from 'react-router-dom'
import AuthService from '../../services/AuthService';
import Services from '../../services/firebaseServices';
import { Link } from 'react-router-dom'
import { faFolder,faFile, faFilePdf, faVideo, faImage, faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from 'react-bootstrap';
import Loader from "react-js-loader";

class MijnFolderContent extends React.Component {

    constructor(props) {
        super(props)    
        this.state = {
            mijnFolderContents: [],
            isLoading: true
        }                
      this.handleClick = this.handleClick.bind(this); 
    //   this.handleLogoutClick = this.handleLogoutClick.bind(this);         

    }

    componentDidMount() {
        
        // const folderId = this.props.match.params.folderId           
        // const roleId = AuthService.userDetail.userRoleId           
        // Services.getMinjFolderOrSubfolderData(folderId, roleId).then((res) => {            
        //     this.setState({mijnFolderContents: res})
        //     res ? this.setState({isLoading:false})   : this.setState({isLoading:true})
        //     console.log('sfsfsfsfolders', this.state.mijnFolderContents)                            
        // }) 
    
        setTimeout(() => {
            this.getFolderData()
        }, 100)
    }

    getFolderData = () => {
        const folderId = this.props.match.params.folderId           
        const roleId = AuthService.userDetail != null ? AuthService.userDetail.userRoleId : '' ;
        Services.getMinjFolderOrSubfolderData(folderId, roleId).then((res) => {            
            this.setState({mijnFolderContents: res})
            res ? this.setState({isLoading:false})   : this.setState({isLoading:true})
            console.log('sfsfsfsfolders', this.state.mijnFolderContents)                            
        })
    }

    handleClick(item) {                
        this.props.history.push({
            pathname: '/pdfviewer',            
            state: { docItem:item}
          })    
    }
        
    handeVideoClick = (item) => {        
        this.props.history.push({
            pathname: '/videoPlayer',            
            state: { docItem:item}
        })    
    }

    // handleLogoutClick = () => {
      
    //     const isLogout = AuthService.logout();
    //     sessionStorage.removeItem('userDetail');
    //     sessionStorage.clear();
    //     console.log('herer');return
        
    //     //  <Redirect to="/login" />       
    //     // props.history.push({pathName:'/login'})   
    //     // window.location = "/login";
    //     if(isLogout){
    //         // this.props.history.push({
    //         //     pathname: '/login',
    //         //     isLogout:true                        
    //         // })
    //         // <Redirect to="/login" />
    //         console.log('here')
    //         window.location = "/";
    //     } else {
    //         alert('sf')
    //     }                    
    // }

    render() {
        const fontStyle = {
            width: "30px"
        }

        const cardStyle = {
        color: "white",
        backgroundColor: "#3C83AE",
        fontFamily: "Arial",      
        width:'100%',
        };

        const backButtonStyle = {
            width: "30px",
            float: "left",
            color:"#fff"
        }

        const noContentStyle = {
            color: '#fff',
            textAlign: 'center'
        }

        const logBtnStyle = {            
            fontSize:'14px',
            fontFamily:'Roboto',
            backgroundColor:'#146095',
            borderColor:'#146095',
            cursor:'pointer'

        }
        return (
            <div className="docs-lists">
                {/* <div className="row">
                    <FontAwesomeIcon className="back-button" style={backButtonStyle}                   
                        size="8x"
                        icon={faLongArrowAltLeft}
                        onClick={this.props.history.goBack}
                    />
                </div> */}

                {/* <div className="logout-btn">
                    <button className="btn btn-primary" style={logBtnStyle} onClick={() => this.handleLogoutClick()}>
                        Logouttt
                    </button>
                </div> */}

            <div className="row back-button backButtonNew" onClick={this.props.history.goBack}>
                <label style={{color:'#fff'}}>
                    <FontAwesomeIcon className="" style={backButtonStyle}                   
                        size="8x"
                        icon={faLongArrowAltLeft}                        
                     />
                     <span>{this.props.match.params.folderName}</span>
                </label>              
            </div>

             {AuthService.userDetail != null ? (
                <div>
                    {
                    this.state.isLoading ? (<div className={"item"}>
                    <Loader type="spinner-default" bgColor={"#FFFFFF"} size={60} />
                    </div>) : 
                        <div  className="container">
                        {this.state.mijnFolderContents != null &&  this.state.mijnFolderContents.length > 0 ? 
                            (
                                <div className="row content-lists">
                                        {
                                            this.state.mijnFolderContents.map((item, key) => (
                                                
                                                <div className="col-3"  key={key}   value={key}>

                                                        {(() => {
                                                                switch (item.contentType) {
                                                                case 'folder':
                                                                    return ( <Link to={`/MijnSubfolderContents/`+item.subfolderId+`/`+item.subFolderName}> 
                                                                                <Card style={cardStyle}>  
                                                                                    <FontAwesomeIcon style={fontStyle}                   
                                                                                    size="8x"
                                                                                    icon={faFolder }
                                                                                    />                                                                    
                                                                                    {item.subFolderName }
                                                                                </Card>
                                                                            </Link>);
                                                                            break
                                                                case 'document':
                                                                    return (<Card style={cardStyle} onClick={() => this.handleClick(item)}>
                                                                                <FontAwesomeIcon style={fontStyle}                   
                                                                                    size="8x"
                                                                                    icon={item.type === 'word' ? faFile : faFilePdf}
                                                                                />                                                                                       
                                                                                {item.title}                                                                                               
                                                                            </Card>);
                                                                    break

                                                                case 'url':
                                                                    return (
                                                                        <a href={item.url} target="__blank">
                                                                            <Card style={cardStyle}>                                
                                                                                <FontAwesomeIcon style={fontStyle}                   
                                                                                size="8x"
                                                                                icon={faImage}
                                                                                />                                
                                                                                {item.title}
                                                                            </Card>
                                                                        </a>
                                                                    );
                                                                    break

                                                                case 'video':
                                                                    return (                                                                   
                                                                        <div onClick={() => this.handeVideoClick(item)}>
                                                                            <Card style={cardStyle}>
                                                                                <FontAwesomeIcon style={fontStyle}                   
                                                                                size="8x"
                                                                                icon={faVideo}
                                                                                />                                                               
                                                                            {item.title}    
                                                                        </Card>
                                                                        </div>
                                                                    );
                                                                    break

                                                                default:
                                                                    return (<div>No content type available</div>);
                                                                }
                                                            })()}                                                   
                                                </div> 
                                            ))
                                        }
                                </div>
                            ) : <div style={noContentStyle}>No contents found</div>
                        }
                        </div>

                    }
                </div>                        
                
            ) 
             :            
            (<Redirect to={{ pathname: '/login'}} /> )}                             
                                                          
            </div>
        )
    };

}

export default MijnFolderContent
