import React from 'react';
// import firebase from '../constant/firebase';
import firebase from '../constant/firebase';

// const firestore = firebase.firestore();
class FirebaseServices extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            docs: []            
        }
        // this.getContentsObj = this.getContentsObj.bind(this);
    }

    // getFolders = () => {       

    //     let fArray = [] ;
    //     return new Promise(function(resolve, reject) {

    //         firebase.firestore().collection('focus/folder')
    //         .startAt(0)
    //         .limit(2).get()
    //         // .ref()
    //         // .child('focus/folder/')
    //         // .once('value')
    //         .then( (snapshot) => {
    //             let dataArray = [] ;
    //             for (const [key, value] of Object.entries(snapshot.val())) {                                      
    //                 let folderObj = {
    //                     id: key,
    //                     name: value.folderName !== null && value.folderName !== undefined ? value.folderName : ''
    //                 } 
    //                 dataArray.push(folderObj)                        
    //             }  
                
    //             console.log(dataArray)
    //             resolve(dataArray)               
    //         }) 
    //     });
    // }

    getWelcomeMessage() {
        return new Promise(function(resolve, reject){

            firebase.database()
            .ref()
            .child('welcome')
            .once('value')
            .then((snapshot) => {
                let messageArray = [] ;
                if(snapshot.val() !== null){
                    for(const[key, value] of Object.entries(snapshot.val())){
                        let msgObj = {
                            id:key,
                            text: value.werlcomeMessage
                        }
                        messageArray.push(msgObj)
                    }
                }
                resolve(messageArray)
            })

        });
    }

    getFolders = (folderId) => {                       
        return new Promise(function(resolve, reject) {
            firebase.database()
            .ref()
            .child('focus/folder/')
            .once('value')
            .then( (snapshot) => {                
                let dataArray = [] ;
                if(snapshot.val() !== null ){
                    for (const [key, value] of Object.entries(snapshot.val())) {                                      
                        let folderObj = {
                            id: key,
                            name: value.folderName !== null && value.folderName !== undefined ? value.folderName : ''
                        } 
                        dataArray.push(folderObj)                        
                    }
                }
                                               
                resolve(dataArray)               
            }) 
        });
    }

    getAllContents = (folderId) => {

        const foldersAllContents = [] ;
        return new Promise(function(resolve, reject) {
            firebase.database()
            .ref()
            .child('focus/focus_data/doc-list')
            .orderByChild('folderId')
            .equalTo(folderId)
            .once('value')
            .then( (snapshot) => {                
                let docList = snapshot.val();
                if(docList !== null ) {                    
                    for(const [key, value] of Object.entries(docList)) {                                      
                        let folderObj = {
                            id: key,
                            folderId: value.folderId !== null && value.folderId !== undefined ? value.folderId : '',
                            subFolderId: value.subFolderId !== null && value.subFolderId !== undefined ? value.subFolderId : '',
                            title: value.title !== null && value.title !== undefined ? value.title : '',
                            type: value.type !== null && value.type !== undefined ? value.type : '',
                            url: value.url !== null && value.url !== undefined ? value.url : '',
                            isPrintable: value.isPrintable,
                            contentType:'doc'
                        } 
                        foldersAllContents.push(folderObj);
                    }
                }



                firebase.database()
                .ref()
                .child('focus/focus_data/random-url-list')
                .orderByChild('folderId')
                .equalTo(folderId)
                .once('value')
                .then( (snapshot) => {                
                    let randomUrlList = snapshot.val();
                    if(randomUrlList !== null ) {                    
                        for(const [key, value] of Object.entries(randomUrlList)) {                                      
                            let folderObj = {
                                id: key,
                                folderId: value.folderId !== null && value.folderId !== undefined ? value.folderId : '',
                                imagePath: value.imagePath !== null && value.imagePath !== undefined ? value.imagePath : '',
                                subFolderId: value.subFolderId !== null && value.subFolderId !== undefined ? value.subFolderId : '',
                                title: value.title !== null && value.title !== undefined ? value.title : '',
                                type: value.type !== null && value.type !== undefined ? value.type : '',
                                url: value.url !== null && value.url !== undefined ? value.url : '',
                                contentType:'image'

                            } 
                            foldersAllContents.push(folderObj);
                        }
                    }
                    

                    firebase.database()
                    .ref()
                    .child('focus/focus_data/video-list')
                    .orderByChild('folderId')
                    .equalTo(folderId)
                    .once('value')
                    .then( (snapshot) => {                
                        let videoList = snapshot.val();
                        if(videoList !== null ) { 
                            let char = '=' ;                   
                            for(const [key, value] of Object.entries(videoList)) {                                      
                                let folderObj = {
                                    id: key,
                                    folderId: value.folderId !== null && value.folderId !== undefined ? value.folderId : '',                                
                                    subFolderId: value.subFolderId !== null && value.subFolderId !== undefined ? value.subFolderId : '',
                                    time: value.time !== null && value.time !== undefined ? value.time : '',
                                    title: value.title !== null && value.title !== undefined ? value.title : '',
                                    type: value.type !== null && value.type !== undefined ? value.type : '',
                                    url: value.url !== null && value.url !== undefined ? value.url : '',
                                    contentType:'video',
                                    youtubeId: value.type.toLowerCase() === 'youtube' ? value.url.substring(value.url.indexOf(char) + 1) : ''

                                } 
                                foldersAllContents.push(folderObj);
                            }
                        }    
                        

                        firebase.database()
                        .ref()
                        .child('focus/subFolder')
                        .orderByChild('folderId')
                        .equalTo(folderId)
                        .once('value')
                        .then( (snapshot) => {                
                            let subFolderList = snapshot.val();
                            if(subFolderList !== null ) {                    
                                for(const [key, value] of Object.entries(subFolderList)) {                                      
                                    let folderObj = {
                                        id: key,
                                        folderId: value.folderId !== null && value.folderId !== undefined ? value.folderId : '',                                
                                        title:  value.subFolderName !== null && value.subFolderName !== undefined ? value.subFolderName : '',
                                        contentType:'subfolder'
                                    } 
                                    foldersAllContents.push(folderObj);
                                }
                            }                                
                            resolve(foldersAllContents) 
                        })                        
                    })
                    
                }) 
            })
            
        });  
       
        
        
    }

    getAllSubfoldersContents = (subFolderId) => {        
        let subfoldersAllContents = [] ;
        return new  Promise((resolve, reject) => {

            firebase.database()
            .ref()
            .child('focus/focus_data/doc-list')
            .orderByChild('subFolderId')
            .equalTo(subFolderId)
            .once('value')
            .then( (snapshot) => {                
                let docList = snapshot.val();                
                if(docList !== null ) {                    
                    for(const [key, value] of Object.entries(docList)) {                                      
                        let folderObj = {
                            id: key,
                            folderId: value.folderId !== null && value.folderId !== undefined ? value.folderId : '',
                            subFolderId: value.subFolderId !== null && value.subFolderId !== undefined ? value.subFolderId : '',
                            title: value.title !== null && value.title !== undefined ? value.title : '',
                            type: value.type !== null && value.type !== undefined ? value.type : '',
                            url: value.url !== null && value.url !== undefined ? value.url : '',
                            contentType:'doc',
                            isPrintable: value.isPrintable !== undefined ? value.isPrintable : '',
                        } 
                        subfoldersAllContents.push(folderObj);
                    }
                } 
                
                firebase.database()
                .ref()
                .child('focus/focus_data/random-url-list')
                .orderByChild('subFolderId')
                .equalTo(subFolderId)
                .once('value')
                .then( (snapshot) => {                
                    let randomUrlList = snapshot.val();
                    if(randomUrlList !== null ) {                    
                        for(const [key, value] of Object.entries(randomUrlList)) {                                      
                            let folderObj = {
                                id: key,
                                folderId: value.folderId !== null && value.folderId !== undefined ? value.folderId : '',
                                imagePath: value.imagePath !== null && value.imagePath !== undefined ? value.imagePath : '',
                                subFolderId: value.subFolderId !== null && value.subFolderId !== undefined ? value.subFolderId : '',
                                title: value.title !== null && value.title !== undefined ? value.title : '',
                                type: value.type !== null && value.type !== undefined ? value.type : '',
                                url: value.url !== null && value.url !== undefined ? value.url : '',
                                contentType:'image'

                            } 
                            subfoldersAllContents.push(folderObj);
                        }
                    }

                    firebase.database()
                    .ref()
                    .child('focus/focus_data/video-list')
                    .orderByChild('subFolderId')
                    .equalTo(subFolderId)
                    .once('value')
                    .then( (snapshot) => {                
                        let videoList = snapshot.val();
                        if(videoList !== null ) { 
                            let char = '=' ;                   
                            for(const [key, value] of Object.entries(videoList)) {                                      
                                let folderObj = {
                                    id: key,
                                    folderId: value.folderId !== null && value.folderId !== undefined ? value.folderId : '',                                
                                    subFolderId: value.subFolderId !== null && value.subFolderId !== undefined ? value.subFolderId : '',
                                    time: value.time !== null && value.time !== undefined ? value.time : '',
                                    title: value.title !== null && value.title !== undefined ? value.title : '',
                                    type: value.type !== null && value.type !== undefined ? value.type : '',
                                    url: value.url !== null && value.url !== undefined ? value.url : '',
                                    contentType:'video',
                                    youtubeId: value.type.toLowerCase() === 'youtube' ? value.url.substring(value.url.indexOf(char) + 1) : ''

                                } 
                                subfoldersAllContents.push(folderObj);
                            }
                        }
                        
                        resolve(subfoldersAllContents)
                    })   
                 })
            })
        })
    }


    getUserRoleId = (userRole) => {        
        return  new Promise((resolve, reject) => {
            firebase.database()
            .ref()
            .child('role-list')
            .orderByChild('roleName')
            .equalTo(userRole)
            .once('value')
            .then( (snapshot) => {                
                const userObj =  {}
                 if(snapshot.val() !== null ) {
                    for(const [key, value] of Object.entries(snapshot.val())) {  
                        userObj.userRoleId =  key 
                        userObj.roleName = value.roleName 
                    }
                 }
                resolve(userObj)                
            })            
        })
    }

    getMinjFolder = (userRoleId) => {            
        return  new Promise((resolve, reject) => {
            firebase.database()
            .ref('Mijn')
            .child('folder')            
            .once('value')
            .then( (snapshot) => {
                
                let folderList = snapshot.val() ;
                let rootContent = [] ;
                 if(folderList !== null ) {
                    for(const [key, value] of Object.entries(folderList)) {  
                        value.roleId.filter((v, k) => {                            
                            if(v == userRoleId) {
                                const fObj = {} ;
                                fObj['folderId'] = key
                                fObj['folderName'] = value.folderName
                                fObj['roleId'] = v
                                fObj['contentType'] = 'folder'
                                rootContent.push(fObj);                                                                                  
                            }
                        })                        
                    } 
                 }
                 firebase.database()
                 .ref('Mijn')
                 .child('Mijn_data/doc-list')            
                 .once('value')
                 .then( (snapshot) => {
                     let  docList  = snapshot.val() ;
                     if(docList !== null){
                         for(const [key, value] of Object.entries(docList)) {                             
                             let docObj = {} ;                                                        
                             if( value.isRoot.length >= 1 ){
                                 value.isRoot.filter((v, k) => {
                                    if(v.roleId !== undefined && v.roleId == userRoleId) {
                                        docObj['folderAccess'] = value.folderAccess
                                        docObj['isPrintable'] = value.isPrintable
                                        docObj['docId'] = key
                                        docObj['isRoot'] = value.isRoot
                                        docObj['type'] = value.type
                                        docObj['url'] = value.url
                                        docObj['title'] = value.title                                         
                                        docObj['contentType'] = 'document'                                        
                                        rootContent.push(docObj);                                        
                                    }
                                })
                            }
     
                        }
                    }                                                        
                    firebase.database()
                    .ref('Mijn')
                    .child('Mijn_data/random-url-list')            
                    .once('value')
                    .then( (snapshot) => {
                        let  urlList  = snapshot.val() ;                        
                        if(urlList !== null){
                            for(const [key, value] of Object.entries(urlList)) {                             
                                let urlObj = {} ;                            
                                if( value.isRoot.length >= 1 ){
                                    value.isRoot.filter((v, k) => {
                                        if(v.roleId !== undefined && v.roleId == userRoleId) {
                                            urlObj['urlId'] = key
                                            urlObj['folderAccess'] = value.folderAccess
                                            urlObj['imagePath'] = value.imagePath
                                            urlObj['isRoot'] = value.isRoot                                            
                                            urlObj['type'] = value.type
                                            urlObj['url'] = value.url
                                            urlObj['title'] = value.title 
                                            urlObj['contentType'] = 'url'                                        
                                            rootContent.push(urlObj);                                        
                                        }
                                    })
                                }
        
                            }
                        }                                                   
                        firebase.database()
                        .ref('Mijn')
                        .child('Mijn_data/video-list')            
                        .once('value')
                        .then( (snapshot) => {
                            let  videoList  = snapshot.val() ;                            
                            if(videoList !== null){
                                for(const [key, value] of Object.entries(videoList)) {                             
                                    let videoObj = {} ;                            
                                    if( value.isRoot.length >= 1 ){
                                        value.isRoot.filter((v, k) => {
                                            if(v.roleId !== undefined && v.roleId == userRoleId) {
                                                videoObj['videoId'] = key
                                                videoObj['folderAccess'] = value.folderAccess
                                                videoObj['time'] = value.time                                                
                                                videoObj['isRoot'] = value.isRoot
                                                videoObj['type'] = value.type
                                                videoObj['url'] = value.url
                                                videoObj['title'] = value.title
                                                videoObj['contentType'] = 'video'                                        
                                                rootContent.push(videoObj);                                        
                                            }
                                        })
                                    }
            
                                }
                            }
                            resolve(rootContent)                                                                                      
                        })

                    }) 

                })                                         
            })            
        })
    }

    getMinjFolderOrSubfolderData = (folderId, roleId) => {                
        return new Promise((resolve, reject) => {
            let folderContents = [] ;
            firebase.database()
            .ref('Mijn')
            .child('subFolder')
            .orderByChild('folderId')
            .equalTo(folderId)
            .once('value')
            .then( (snapshot) => {       
                let subfolderList = snapshot.val() ;                
                 if(subfolderList !== null ) {
                    for(const [key, value] of Object.entries(subfolderList)) {
                        const subfolderObj =  {};
                        subfolderObj['subfolderId'] = key   
                        subfolderObj['folderId'] = value.folderId   
                        subfolderObj['subFolderName'] = value.subFolderName
                        subfolderObj['contentType'] = 'folder'
                        folderContents.push(subfolderObj)                           
                    }
                }

                firebase.database()
                .ref('Mijn')
                .child('Mijn_data/doc-list')                
                .once('value')
                .then( (snapshot) => {       
                    let folderDocList = snapshot.val() ;                    
                    if(folderDocList !== null ) {
                        
                        for(const [key, value] of Object.entries(folderDocList)) {
                            const folderDocObj =  {};                            
                            if(value.folderAccess !== undefined && value.folderAccess !== null && value.folderAccess.length > 0 ){
                                for(const [faccessKey, faccessValue] of Object.entries(value.folderAccess)) {
                                    if(faccessValue.folderId ===  folderId && faccessValue.roleId === roleId) {
                                        folderDocObj['folderDocId'] = key
                                        folderDocObj['folderAccess'] = value.folderAccess
                                        folderDocObj['isRoot'] = value.isRoot
                                        folderDocObj['title'] = value.title
                                        folderDocObj['type'] = value.type
                                        folderDocObj['url'] = value.url
                                        folderDocObj['contentType'] = 'document'
                                        folderContents.push(folderDocObj)
                                    }                                    
                                }
                            }                                                       
                        }
                    }
                    
                    firebase.database()
                    .ref('Mijn')
                    .child('Mijn_data/random-url-list')                
                    .once('value')
                    .then( (snapshot) => {       
                        let folderUrlList = snapshot.val() ;                        
                        if(folderUrlList !== null ) {
                            for(const [key, value] of Object.entries(folderUrlList)) { 
                                const folderUrlObj =  {};
                                if(value.folderAccess !== undefined && value.folderAccess !== null && value.folderAccess.length > 0 ){
                                    for(const [faccessKey, faccessValue] of Object.entries(value.folderAccess)) {
                                        if(faccessValue.folderId ===  folderId && faccessValue.roleId === roleId) {
                                            folderUrlObj['folderUrlId'] = key
                                            folderUrlObj['folderAccess'] = value.folderAccess
                                            folderUrlObj['imagePath'] = value.imagePath
                                            folderUrlObj['isRoot'] = value.isRoot
                                            folderUrlObj['title'] = value.title
                                            folderUrlObj['type'] = value.type
                                            folderUrlObj['url'] = value.url
                                            folderUrlObj['contentType'] = 'url'
                                            folderContents.push(folderUrlObj)
                                        }                                    
                                    }
                                }                                                       
                            }
                        }
                        
                        firebase.database()
                        .ref('Mijn')
                        .child('Mijn_data/video-list')                
                        .once('value')
                        .then( (snapshot) => {       
                            let folderVideoList = snapshot.val() ;                            
                            if(folderVideoList !== null ) {
                                for(const [key, value] of Object.entries(folderVideoList)) {   
                                    const folderVideoObj =  {};
                                    if(value.folderAccess !== undefined && value.folderAccess !== null && value.folderAccess.length > 0 ){
                                        for(const [faccessKey, faccessValue] of Object.entries(value.folderAccess)) {
                                            if(faccessValue.folderId ===  folderId && faccessValue.roleId === roleId) {
                                                folderVideoObj['folderVideoId'] = key
                                                folderVideoObj['folderAccess'] = value.folderAccess
                                                folderVideoObj['time'] = value.time
                                                folderVideoObj['isRoot'] = value.isRoot
                                                folderVideoObj['title'] = value.title
                                                folderVideoObj['type'] = value.type
                                                folderVideoObj['url'] = value.url
                                                folderVideoObj['contentType'] = 'video'
                                                folderContents.push(folderVideoObj)
                                            }                                    
                                        }
                                    }                                                       
                                }
                            }
                            
                            resolve(folderContents)
                        })
                    }) 

                })                
            })
        })
    }

    getMijnSubFolderContent = (subfolderId, roleId) => {        
        return new Promise((resolve, reject) => {
            let subfolderContents = [] ;
            firebase.database()
            .ref('Mijn')
            .child('Mijn_data/doc-list')                
            .once('value')
            .then( (snapshot) => {       
                let subfolderDocList = snapshot.val() ;                
                if(subfolderDocList !== null ) {                    
                    for(const [key, value] of Object.entries(subfolderDocList)) { 
                        const subfolderDocObj =  {};                           
                        if(value.folderAccess !== undefined && value.folderAccess !== null && value.folderAccess.length > 0 ){
                            for(const [faccessKey, faccessValue] of Object.entries(value.folderAccess)) {
                                if(faccessValue.folderId ==  subfolderId && faccessValue.roleId == roleId) {
                                    subfolderDocObj['folderDocId'] = key
                                    subfolderDocObj['folderAccess'] = value.folderAccess
                                    subfolderDocObj['isRoot'] = value.isRoot
                                    subfolderDocObj['title'] = value.title
                                    subfolderDocObj['type'] = value.type
                                    subfolderDocObj['url'] = value.url
                                    subfolderDocObj['contentType'] = 'document'
                                    subfolderContents.push(subfolderDocObj)
                                }                                    
                            }
                        }                                                       
                    }
                }

                firebase.database()
                .ref('Mijn')
                .child('Mijn_data/random-url-list')                
                .once('value')
                .then( (snapshot) => {       
                    let subfolderUrlList = snapshot.val() ;                    
                    if(subfolderUrlList !== null ) {                        
                        for(const [key, value] of Object.entries(subfolderUrlList)) {                            
                            const subfolderUrlObj =  {};
                            if(value.folderAccess !== undefined && value.folderAccess !== null && value.folderAccess.length > 0 ){
                                for(const [faccessKey, faccessValue] of Object.entries(value.folderAccess)) {
                                    if(faccessValue.folderId ===  subfolderId && faccessValue.roleId === roleId) {
                                        subfolderUrlObj['folderUrlId'] = key
                                        subfolderUrlObj['folderAccess'] = value.folderAccess
                                        subfolderUrlObj['imagePath'] = value.imagePath
                                        subfolderUrlObj['isRoot'] = value.isRoot
                                        subfolderUrlObj['title'] = value.title
                                        subfolderUrlObj['type'] = value.type
                                        subfolderUrlObj['url'] = value.url
                                        subfolderUrlObj['contentType'] = 'url'
                                        subfolderContents.push(subfolderUrlObj)
                                    }                                    
                                }
                            }                                                       
                        }
                    }

                    firebase.database()
                    .ref('Mijn')
                    .child('Mijn_data/video-list')                
                    .once('value')
                    .then( (snapshot) => {       
                        let subfolderVideoList = snapshot.val() ;                        
                        if(subfolderVideoList !== null ) {                            
                            for(const [key, value] of Object.entries(subfolderVideoList)) {                            
                                const subfolderVideoObj =  {};
                                if(value.folderAccess !== undefined && value.folderAccess !== null && value.folderAccess.length > 0 ){
                                    for(const [faccessKey, faccessValue] of Object.entries(value.folderAccess)) {
                                        if(faccessValue.folderId ===  subfolderId && faccessValue.roleId === roleId) {
                                            subfolderVideoObj['folderVideoId'] = key
                                            subfolderVideoObj['folderAccess'] = value.folderAccess
                                            subfolderVideoObj['time'] = value.time
                                            subfolderVideoObj['isRoot'] = value.isRoot
                                            subfolderVideoObj['title'] = value.title
                                            subfolderVideoObj['type'] = value.type
                                            subfolderVideoObj['url'] = value.url
                                            subfolderVideoObj['contentType'] = 'video'
                                            subfolderContents.push(subfolderVideoObj)
                                        }                                    
                                    }
                                }                                                       
                            }
                        }
                        
                        resolve(subfolderContents)
                    })
                })
            }) 
        })
    }   
}

const firebaseServices = new FirebaseServices();
export default firebaseServices;