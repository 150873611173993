import React from "react";
import "../../assets/css/custom.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import Serivces from '../../services/firebaseServices';
import { Markup } from 'interweave';
import { Link } from 'react-router-dom';
import Loader from "react-js-loader";

const buttonStyle = {
  backgroundColor: "#3C83AE",
  border: "solid #3C83AE 1px",
  borderRadius: "50px",
  width:"20%",
  textAllign:"center",
  color:"#fff"     
}

const fSize  = {
  fontSize: "15px"
}

const backButtonStyle = {
  width: "20px",
  color: "rgb(255, 255, 255)",
  height: "32px",
  float: "right"
}

// const paragraphStyle = {
//   color: '#FFFFFF',
//   textShadow: '0px 3px 6px #0000005E',
//   textAlign:"center",
//   fontSize: "14px"
// }
const containerStyle = {
  textAlign:"center"
}

class Home extends React.Component {

  constructor(props){
    super(props) 
    this.state = {
      welcomeText: '',
      isLoading:false
    }   
  }

  componentDidMount() {
    Serivces.getWelcomeMessage().then(res => {                  
        this.setState({welcomeText: res.length > 0 && res[0].text !== undefined ? res[0].text : 'No text found' })
        this.setState({isLoading:true})
    })
  }
  render() { 
  return (
    <div className="container custom-sec-mw" style={containerStyle}>
      <div className="home-content">  

      {!this.state.isLoading ? (<div className={"item"}>
          <Loader type="spinner-default" bgColor={"#FFFFFF"} size={60} />
        </div>) : (<div><Markup className="center-text"  content={this.state.welcomeText} />
          <Link className="start-button" style={buttonStyle} to="/foocus2"><span style={fSize}>
                  <span>Get Started</span>
                    <span className="start-arrow">
                      <FontAwesomeIcon className="back-button" style={backButtonStyle}                   
                            size="8x"
                            icon={faLongArrowAltRight}                        
                        />
                    </span>
                  </span>
          </Link></div>)}       
        </div>
    </div>
  )};
};

export default Home;
