
import firebase from 'firebase';

const config = {  
    apiKey: "AIzaSyAEs5c1crehclGHRMez6jSBEavDxiWNWkc",
    authDomain: "foocus2-e8f87.firebaseapp.com",
    databaseURL: "https://foocus2-e8f87.firebaseio.com",
    projectId: "foocus2-e8f87",
    storageBucket: "foocus2-e8f87.appspot.com",
    messagingSenderId: "322083054505",
    appId: "1:322083054505:web:6b5ac8c12c269cfe541ab8",
    measurementId: "G-HMW6WRESVR"
};

// const config = {  
//     apiKey: "AIzaSyANwAzYmDKkvCCppLUpYChoEUMJK-WUgGE",
//     authDomain: "react-firebase-d02ea.firebaseapp.com",
//     projectId: "react-firebase-d02ea",
//     storageBucket: "react-firebase-d02ea.appspot.com",
//     messagingSenderId: "265258388013",
//     appId: "1:265258388013:web:0ad3ed5f11a101db4306a4",
//     databaseURL:"https://react-firebase-d02ea-default-rtdb.firebaseio.com/"
// };

firebase.initializeApp(config);

export default firebase;

export const database = firebase.database();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const messaging = firebase.messaging();
export const firestore = firebase.firestore();