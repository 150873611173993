
import React from "react";
import Serivces from '../../services/firebaseServices';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFilePdf, faFolder, faLongArrowAltLeft, faVideo } from "@fortawesome/free-solid-svg-icons";
// import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
// import testpdf from '../../files/timesheet.pdf';
// import mydocument from '../../files/activities-flow.docx';
// import { Worker } from '@react-pdf-viewer/core';
// import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
// Plugins
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

// import ReactPlayer from 'react-player'
// import ReactPlayer from 'react-player/youtube'
// import YouTube from 'react-youtube';    
// import Pagination from "react-js-pagination";
// import ContentsPagination from '../common/ContentsPagination';
import Pagination from '../common/Pagination';

import { Link } from 'react-router-dom';
// import Pdfviewer from '../common/Pdfviewer';
import Loader from "react-js-loader";

class Contents extends React.Component {
    
  constructor(props) {
    super(props)  
    this.state = {        
        folders:[],
        isLoading:true        
      }           
      this.onChangePage = this.onChangePage.bind(this);         
      this._onClick = this._onClick.bind(this);         
      this.handleClick = this.handleClick.bind(this);         

  }

  componentDidMount() {
    Serivces.getAllContents(this.props.match.params.folderId).then(res => {          
        console.log(res)
        this.setState({folders: res,isLoading: false})         
    })        
  }

  onChangePage(pageItems) {    
    this.setState({ pageItems: pageItems });
  }

  _onClick(event) {      
    event.target.playVideo();
  }

  handleClick(item) {
                
    this.props.history.push({
        pathname: '/pdfviewer',            
        state: { docItem:item}
      })    
  }

  handeVideoClick = (item) => {        
    this.props.history.push({
        pathname: '/videoPlayer',            
        state: { docItem:item}
    })    
  }

  render() {    
    const cardStyle = {
        color: "white",
        backgroundColor: "#3C83AE",        
        fontFamily: "Arial",      
        width:'100%',
        
      };
  
      const divStyle = {
        textAllign:"center",
      };
  
      const fontStyle = {
        width: "30px"
      }      
    //   const opts = {
    //     height: '150px',
    //     width: '100%'       
    //   };
      const backButtonStyle = {
        width: "30px",
        float: "left",
        color:"#fff"
      }

    return (       
        <div className="docs-lists">
            <div className="row back-button backButtonNew" onClick={this.props.history.goBack}>
                <label style={{color:'#fff'}}>
                    <FontAwesomeIcon className="" style={backButtonStyle}                   
                        size="8x"
                        icon={faLongArrowAltLeft}                        
                     />
                     <span>{this.props.match.params.folderName}</span>
                </label>              
            </div>

             {this.state.isLoading ? (<div className={"item"}>
                    <Loader type="spinner-default" bgColor={"#FFFFFF"} size={60} />
            </div>) : 
                <div>
                    {this.state.pageItems != null && this.state.folders.length > 0 ? (
                        <div  className="container" >
                            
                            <div className="row content-lists">
                            {this.state.pageItems.map((item , key) => (                
                            <div className="col-3"  key={key} style={divStyle} value={key}>                  
                                {(() => {            
                                switch (item.contentType) {
                                case 'doc':
                                    return (                        
                                        <Card style={cardStyle} onClick={() => this.handleClick(item)}>
                                            <FontAwesomeIcon style={fontStyle}                   
                                                size="8x"
                                                icon={item.type === 'word' ? faFile : faFilePdf}
                                            />                                                                                       
                                            {item.title}                                                                                               
                                        </Card>                                                                                                      
                                    )
                                    break                            

                                case 'image':
                                    return (
                                        <a href={item.url} target="__blank">
                                                                        
                                            <Card style={cardStyle}>                                
                                                {/* <FontAwesomeIcon style={fontStyle}                   
                                                size="8x"
                                                icon={faImage}
                                                />                                 */}
                                                    <img src={item.imagePath} style={{width: '185px'}} />
                                                {item.title}
                                            </Card>
                                        </a>
                                    )
                                    break
                                
                                case 'video':
                                    return (
                                        <div onClick={() => this.handeVideoClick(item)}>
                                            <Card style={cardStyle}>
                                                <FontAwesomeIcon style={fontStyle}                   
                                                size="8x"
                                                icon={faVideo}
                                                />                               
                                                {item.title}    
                                            </Card>
                                        </div>
                                    )
                                    break

                                case 'subfolder':
                                    return (
                                        <Link to={`/subFoldersContents/`+item.id+`/`+item.title}> 
                                            <Card style={cardStyle}>  
                                                <FontAwesomeIcon style={fontStyle}                   
                                                    size="8x"
                                                    icon={faFolder}
                                                />                                            
                                            {item.title}
                                            </Card>
                                        </Link>  
                                    )
                                    break

                                default:
                                    return (
                                        <div style={{color:'#fff'}}>No content Type available</div>
                                    )
                                }
                                })()}
                                                        
                            </div>                                 
                            ))}           
                        </div>
                        </div>          
                        ) : (
                        <div style={{color:'#fff'}}>No content available</div>
                        )}        
                </div>
            }                    
         {this.state.folders.length > 0 || this.state.folders.length >= 9 ?  (<div className="container">
                   <Pagination items={this.state.folders} onChangePage={this.onChangePage} />
                </div>) : '' }                  
      </div>
    );
  }

}

export default Contents;


