import React from "react";
import Serivces from '../../services/firebaseServices';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
// import Pagination from "react-js-pagination";
import Pagination from '../common/Pagination';
import Loader from "react-js-loader";
import { Link } from 'react-router-dom'

class Foocus2 extends React.Component {
    
  

  constructor(props) {
      super(props)       
      this.state = {        
        folders:[],
        isLoading: true        
      } 
      //  this.handleFoldersClick = this.handleFoldersClick.bind(this);         
       this.onChangePage = this.onChangePage.bind(this);         

  }
  
  componentDidMount() {    
    Serivces.getFolders().then(res => {             
      this.setState({folders: res, isLoading:false})             
      // this.setState({isLoading:false})
      // res.length <= 0 ? this.setState({showNoFoldersText:true}) : this.setState({showNoFoldersText:false})
    })  
  }

  // handleFoldersClick(folderId) {        

  //   // Serivces.getSubfolders(folderId).then(res => {
  //   //   this.setState({subFolders: res })
  //   // })

  //   console.log(folderId)
  // }

  onChangePage(pageOfItems) {    
    this.setState({ pageOfItems: pageOfItems });
  }

  render() {

    const cardStyle = {
    };

    // const divStyle = {
    //   width: "50%",
    //   padding: "10px",
    //   fontSize: "15px",
    //   textAllign:"center"
      
    // };

    const fontStyle = {
      width: "30px"
    }
   

    return (
      <div className="docs-lists">

        {/* <div className="page-heading">
            Foocus2
        </div> */}

        {this.state.isLoading ? (<div className={"item"}>
          <Loader type="spinner-default" bgColor={"#FFFFFF"} size={60} />
        </div>) : 
        
        <div>
          {this.state.pageOfItems != null && this.state.folders.length > 0 ? (
          <div  className="container">
             <div className="row content-lists" style={{marginTop:'10%'}}>
              {this.state.pageOfItems.map((item , key) => (
                <div className="col-3"  key={key}   value={key}>
                    <Link to={`contents/`+item.id+`/`+item.name}> 
                      <Card style={cardStyle}>  
                        <FontAwesomeIcon style={fontStyle}                   
                          size="8x"
                          icon={faFolder}
                        />    
                                      
                          {item.name}
                      </Card>
                    </Link>                     
                </div>                                 
              ))}           
             </div>
              
          </div>          
        ) : (
          <div style={{color:'#fff'}}>No Folders available</div> 
        )}        
        </div>        
        } 
        { this.state.folders.length > 0 || this.state.folders.length >= 9 ?  (<div className="container">
                  <Pagination items={this.state.folders} onChangePage={this.onChangePage} />
          </div>                  ) : '' }                         
      </div>
    );
  }

}

export default Foocus2;


