import React, { useState } from "react";
import { withRouter, Link, Redirect  } from "react-router-dom";
// import styled from "styled-components";
import  logo from '../assets/images/logo.png';
import AuthService from '../services/AuthService';

const HamburgerMenu = props => {

  const [showMenu, setMenu] = useState(false);  
  const [click, setClick] = useState(false);
  
  const handleClick = () => {
    
    setClick(!click)
  };  
  const  handleLogoutClick = () => {
      
    const isLogout = AuthService.logout();
    
    if(isLogout) {  
      //  <Redirect to="/login" />       
      // props.history.push({pathName:'/login'})   
      window.location = "/login";
    }
  }

  const toggleClass =() => {    
    setMenu(!showMenu);
  }
  
  const closeMenu =() => {    
    setMenu(false);
  }
  
  return (
    <div className="container custom-header-container">
      

      <nav className="navbar navbar-expand-lg bg-transparent w-100">
        <div className="container-fluid">          
          <div className="logo">
             <img src={logo} alt="logo"/>
           </div>
          <button  
              onClick={toggleClass}  className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={showMenu ? 'collapse navbar-collapse show': 'collapse navbar-collapse'} id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className={props.location.pathname === '/' ? "nav-link active" : "nav-link"} aria-current="page" onClick={handleClick, closeMenu} to="/">Start</Link>
              </li>
              <li className="nav-item">
                <Link   className={props.location.pathname === '/foocus2' ? "nav-link active" : "nav-link"} onClick={handleClick, closeMenu} to="/foocus2">
                  Foocus2
                </Link>
              </li>
              <li className="nav-item">
                <Link className={props.location.pathname === '/mijnData' ? "nav-link active" : "nav-link"} onClick={handleClick, closeMenu} to="/login">
                  Mijn training
              </Link>
              </li>                      
                {
                    AuthService.userDetail && AuthService.userDetail !== null  ? (<li className="nav-item"><Link className="nav-link" onClick={handleLogoutClick}>
                    Logout
                  </Link></li>) : ''                          
                }          
            			
              <li className="nav-item">
                <a className="" className="nav-link" target="__blank"  href="https://www.clooser2.nl/lidmaatschappen/">
                   Contact
                 </a>
              </li>           
            </ul>
          </div></div>        
      </nav>

    </div>

  )
}

export default withRouter(HamburgerMenu);
