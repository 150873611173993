import React from "react";
import "../../assets/css/custom.css";
import axios from 'axios';
// import AuthService from '../../services/AuthService';
import { faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


// import { faHeadSideVirus } from "@fortawesome/free-solid-svg-icons";

export default class ForgotPassword extends React.Component {

  constructor(props){
    super(props)
    this.state = {email: '',  data:'', errorMessage:''}    
  }

  componentDidMount() {

    // if( AuthService.userDetail !== null ) {
    //   this.props.history.push({
    //     pathname: '/mijnData'
    //   })
    // }
  }
  handleEmailChange = (event) => {
      this.setState({email: event.target.value})
  }

//   handlePasswordChange = (event) => {
//       this.setState({password: event.target.value})
//   }

  handleSubmit = (e) => {
    e.preventDefault()    
    var bodyFormData = new FormData();
    bodyFormData.append('email', this.state.email);            
          
      return axios({
        method: "post",
        // url: "http://localhost/api/forgetpassword.php",
        url: "https://test.foocus2.nl/api/forgetPassword.php",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
          console.log('sf', response)                    
          response.data.status !== undefined && response.data.status !== 'error' ? this.setState({data:response.data}) : this.setState({errorMessage:response.data.message})                    
          if(response.data.status !== 'error') {            
            this.props.history.push({
              pathname: '/login',
              data:response.data,
              isLinkSend:true
            })
          }                    
        })
        .catch((response) => {        
          console.log(response);
        });
  }

  render() {    
    const submitButtonStyle = {
      backgroundColor: "#3C83AE",
      border: "solid #3C83AE 1px",
      borderRadius: "4px",
      width:"100%",
      textAllign:"center"     
    }
    const errorStyle = {
      color:'#ff0000',
      fontSize:'15px'
    }

    const backButtonStyle = {
        width: "30px",
        float: "left",
        color:"#fff"
    }

    return  (
        <div className="container">

              <div className="row back-button backButtonNew" onClick={this.props.history.goBack}>
                <label style={{color:'#fff'}}>
                    <FontAwesomeIcon className="" style={backButtonStyle}                   
                        size="8x"
                        icon={faLongArrowAltLeft}                        
                     />                     
                </label>              
            </div>

            {/* <div className="row">
                <FontAwesomeIcon className="back-button" style={backButtonStyle}                   
                    size="8x"
                    icon={faLongArrowAltLeft}
                    onClick={this.props.history.goBack}
                />
            </div> */}
            <div className="box login-box">          
                <h2>Forgot password</h2>
                <form onSubmit={this.handleSubmit}>
                <div className="inputBox">
                    <input type="email" name="email" required   onChange={this.handleEmailChange} value={this.state.email} />
                    <label>Email</label>
                </div>
                {/* <div className="inputBox">
                    <input type="password" name="password" required                               
                        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" 
                        onChange={this.handlePasswordChange} 
                        value={this.state.password} 
                        />
                    <label>Password</label>
                </div>           */}
                    {!this.state.errorMessage !== '' ? (<div style={errorStyle}>{this.state.errorMessage}</div>) : ''}                    
                <button  type="submit" style={submitButtonStyle}><span className="login-text">INLOGGEN</span></button>
                </form>
            </div>
        </div>
    );
  }

}