import React from 'react';
import YouTube from 'react-youtube';
// import Vimeo from 'react-vimeo';
import "../../assets/css/custom.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft} from "@fortawesome/free-solid-svg-icons";

export default class VideoPlayer extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            videoId:'',
            videoType:''
        }
    }

    componentDidMount() {

        let url = this.props !== null && this.props.location.state !== undefined  ? this.props.location.state.docItem.url : '' ;
        let type = this.props !== null && this.props.location.state !== undefined  ? this.props.location.state.docItem.type.toLowerCase() : '' ;
        console.log('type', type)
        this.setState({videoType: type})
        console.log(url)
        console.log(type)
        let regExp = type !== '' && type.toLowerCase() == 'youtube' ? /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/ : /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/ ;        
        let match = [] ;
        match =  url.match(regExp)  ;

        console.log('regExp', regExp)
        console.log('match', this.props)
        console.log('match', match[7])
        
        let vId =  '' ;


        if(type == 'youtube'){
            vId =  match[7] !== undefined ?  match[7] : '' 
        }else {
            vId =  match[2] !== undefined ? match[2] : ''  
        }          
        this.setState({videoId:vId})        
   
    }

    render() {
        const opts = {
            height:'500px',            
            width: '100%',
            playerVars: {            
                autoplay: 1,
            },
        };     
        const backButtonStyle = {
            width: "30px",
            float: "left",
            color:"#fff"
        }   
        return (           
            <div className="container">
                 <div className="row back-button backButtonNew" onClick={this.props.history.goBack}>
                        <label style={{color:'#fff'}}>
                            <FontAwesomeIcon className="" style={backButtonStyle}                   
                                size="8x"
                                icon={faLongArrowAltLeft}                        
                            />                        
                        </label>                                      
                    </div>
                <div className="row">
                    {/* <FontAwesomeIcon className="back-button" style={backButtonStyle}                   
                        size="8x"
                        icon={faLongArrowAltLeft}
                        onClick={this.props.history.goBack}
                    /> */}

                   

                </div>
                { this.state.videoType === 'youtube'? (<YouTube videoId={this.state.videoId} opts={opts} onReady={this._onReady}  />) :
                     <iframe src={`https://player.vimeo.com/video/`+this.state.videoId+`?&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1`} style={{position:'absolute',left:'0', width:'100%',height:'80%'}}  frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>
                    </iframe>                    
                    // <Vimeo videoId={this.state.videoId}/>                       
                }
            </div>
        );
    }

    _onReady(event) {        
        event.target.playVideo();
    }
}