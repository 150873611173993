
import React from "react";
// import Serivces from '../../services/firebaseServices';
// import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
// import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
// import testpdf from '../../files/timesheet.pdf';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
// import { Document, Page } from 'react-pdf';
// import Loader from "react-js-loader";
// import logger from 'logging-library';
import FileViewer from 'react-file-viewer';
// import { CustomErrorComponent } from 'custom-error';


class Pdfviewer extends React.Component {
    
  constructor(props) {
    super(props)         
  }

  
  render() {
    
    const divStyle = {
        height: '550px',        
    }

    const backButtonStyle = {
        width: "30px",
        float: "left",
        color:"#fff",
        border: '1px solid #ccc',
        borderRadius: '100%',
        width: '24px !important',
        height: '24px !important',
        padding: '4px',
        fontSize:'30px'       
    }
    return ( 

        <div  style={divStyle} className="doc-view-main">
            <div className="row d-flex justify-content-between align-items-center">
                <FontAwesomeIcon className="back-button" style={backButtonStyle}                                       
                    icon={faLongArrowAltLeft}
                    onClick={this.props.history.goBack}
                /> 
               
                <div className="print-btn">                
                    {this.props.location.state.docItem.isPrintable ?  (<a className="btn btn-primary" href={this.props.location.state.docItem.url} target="__blank">Print</a> ) : '' }
                </div>                       
            </div>
            
            { this.props.location.state.docItem !== null && this.props.location.state.docItem !== undefined ?
              (
                  this.props.location.state.docItem.type === 'pdf' ?
                   (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                        {this.props.location.state.file !== null ? (<Viewer fileUrl={this.props.location.state.docItem.url} />) : ''}
                    </Worker> 
                   ) : 
                    ( <FileViewer
                        fileType={this.props.location.state.docItem.type !== undefined && this.props.location.state.docItem.type === 'docx' ?
                         this.props.location.state.docItem.type : 'docx'}
                        filePath={this.props.location.state.docItem.url}/>
                    ) 
                ) :
                (<div>No file found</div>) }
        </div>
    );
  }

}

export default Pdfviewer;


