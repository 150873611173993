import React from "react";
// import ShareBtn from 'react-share-button';
const MediaLink = () => {
  return (
    <>
      <h1>Mesdia</h1>
    </>
  );
};

export default MediaLink;
