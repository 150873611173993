import React from "react";
import "../../assets/css/custom.css";
import axios from 'axios';
import AuthService from '../../services/AuthService';
import { Link } from 'react-router-dom'

// import { faHeadSideVirus } from "@fortawesome/free-solid-svg-icons";

export default class Login extends React.Component {

  constructor(props){
    super(props)
    this.state = {email: '', password: '', data:'', errorMessage:''}    
  }

  componentDidMount() {        
    if(this.props.location.isLogout) {
      this.props.history.push({
        pathname: '/login'
      })
    } else if( AuthService.userDetail !== null && AuthService.userDetail !== '' ) {      
      this.props.history.push({
        pathname: '/mijnData'
      })
    }
    
  }
  handleEmailChange = (event) => {
      this.setState({email: event.target.value})
  }

  handlePasswordChange = (event) => {
      this.setState({password: event.target.value})
  }

  handleSubmit = (e) => {
    e.preventDefault()    
    var bodyFormData = new FormData();
    bodyFormData.append('email', this.state.email);
    bodyFormData.append('password', this.state.password);
          
      axios({
        method: "post",
        // url: "http://localhost/api/",
        url: "https://app-focus2.clooser2.nl/api/",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {                    
          response.data.status !== undefined && response.data.status !== 'error' ? this.setState({isLogin: true, data:response.data}) : this.setState({isLogin:false, errorMessage:response.data.data})
          sessionStorage.setItem('userDetail', response.data.user !== undefined ? JSON.stringify(response.data) : '' );          
          if(this.state.isLogin) {
            AuthService.setAuthUser(); 
            this.props.history.push({
              pathname: '/mijnData'
            })
          }                    
        })
        .catch((response) => {        
          console.log(response);
        });
  }

  render() {    
    const submitButtonStyle = {
      backgroundColor: "#3C83AE",
      border: "solid #3C83AE 1px",
      borderRadius: "4px",
      width:"100%",
      textAllign:"center"     
    }
    const errorStyle = {
      color:'#ff0000',
      fontSize:'15px'
    }

    return  (
      <div className="box login-box">
        <h2>INLOGGEN</h2>
        <form onSubmit={this.handleSubmit}>
          <div className="inputBox">
            <input type="email" name="email" required   onChange={this.handleEmailChange} value={this.state.email}  />
            <label>Email</label>
          </div>
          <div className="inputBox">
            <input type="password" name="password" required                   
                  // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" 
                  onChange={this.handlePasswordChange} 
                  value={this.state.password} 
                  />
            <label>Wachtwoord</label>
          </div>          
            {!this.state.isLogin ? (<div style={errorStyle}>{this.state.errorMessage}</div>) : ''}          
            {this.props.location.isLinkSend ? (<div style={errorStyle}>Reset password link has been sent to your mail successfully !</div>) : ''}          
              <Link className="forgot-pwd bg-transparent border-0 d-flex justify-content-end" to="/forgotPassword" style={{color:'#3c83ae'}}>Wachtwoord vergeten?</Link>
          <button  type="submit" style={submitButtonStyle}><span className="login-text">INLOGGEN</span></button>
        </form>
      </div>
    );
  }

}