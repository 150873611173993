import React from 'react';
import "./App.css";
import HamburgerMenu from "./components/HamburgerMenu";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Pages/Home";
import Foocus2 from "./components/Pages/Foocus2";
import MediaLink from "./components/Pages/MediaLink";
import Login from "./components/Pages/Login";
// import Contact from "./components/Pages/Contact";
import Contents from "./components/Pages/Contents";
import SubFoldersContents from "./components/Pages/SubFoldersContents";
import MinjData  from "./components/Pages/MinjData";
import MijnFolderContent from './components/Pages/MijnFolderContent';
import MijnSubfolderContents from './components/Pages/MijnSubfolderContents';
import Pdfviewer from './components/common/Pdfviewer';
import "./assets/css/custom.css";
import AuthService from './services/AuthService';
import ForgotPassword from './components/Pages/ForgotPassword';
import VideoPlayer from './components/common/VideoPlayer';

AuthService.setAuthUser();    

function App() {
  return (
    <>
      <Router>
        <div className="header-fullw">
          <HamburgerMenu />
        </div>
        <div className="container">
          <div className="pages">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/foocus2" component={Foocus2} />
              <Route path="/mediaLink" component={MediaLink} />
              <Route path="/login" component={Login} />
              {/* <Route path="/contact"  component={() => { 
                window.location.href = 'https://www.clooser2.nl/lidmaatschappen/'; 
                return null;
              }} /> */}
              <Route path="/contents/:folderId/:folderName" component={Contents} />
              <Route path="/subFoldersContents/:subFolderId/:subfolderName" component={SubFoldersContents} />
              <Route path="/pdfviewer" component={Pdfviewer} />
              <Route path="/mijnData" component={MinjData} />              
              <Route path="/mijnFolderContents/:folderId/:folderName" component={MijnFolderContent} />              
              <Route path="/MijnSubfolderContents/:subfolderId/:subfolderName" component={MijnSubfolderContents} />              
              <Route path="/forgotPassword" component={ForgotPassword} />              
              <Route path="/videoPlayer" component={VideoPlayer} />              
            </Switch>
            </div>
          </div>
      </Router>
    </>
  );
}

export default App;
