import React  from 'react';
import { Redirect } from 'react-router-dom'
import AuthService from '../../services/AuthService';
import Services from '../../services/firebaseServices';
import { Link } from 'react-router-dom'
import { faFolder,faFile, faFilePdf, faVideo, faImage} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from 'react-bootstrap';
import Loader from "react-js-loader";



class MinjData extends React.Component {

    constructor(props) {
        super(props)    
        this.state = {
            minjFolders: [],
            isLoading: false
        }     
      this.handleClick = this.handleClick.bind(this);         
    //   this.handleLogoutClick = this.handleLogoutClick.bind(this);         

    }

    componentDidMount() {
        // setTimeout(() => {

        //     Services.getMinjFolder(AuthService.userDetail.userRoleId).then((res) => {            
        //         this.setState({minjFolders: res})
        //         res ? this.setState({isLoading:false})   : this.setState({isLoading:true})    
                
        //         console.log('onrefresh',this.state.minjFolders)
        //     })

        // }, 5000) 
        
        if(AuthService.userDetail == undefined || AuthService.userDetail == null || AuthService.userDetail == '' ) {            
            this.props.history.push({
                pathName:'/login'
            })
        }

        setTimeout(() => {
            this.getRootContents();
        }, 2000) 
                
    }

    getRootContents = () => {
        this.setState({minjFolders: []})        
        if(AuthService.userDetail != null) {
            Services.getMinjFolder(AuthService.userDetail.userRoleId).then((res) => {            
                this.setState({minjFolders: res, isLoading: true})                                                        
            })
        }          
    }


    handleClick(item) {                
        this.props.history.push({
            pathname: '/pdfviewer',            
            state: { docItem:item}
          })    
    }

    // handleLogoutClick = () => {        
    //     AuthService.logout();
    //     sessionStorage.removeItem('userDetail');
    //     sessionStorage.clear();
        
        
    //     console.log(this.props)
    //     //  <Redirect to="/login" />       
    //     // props.history.push({pathName:'/login'})   
    //     // window.location = "/login";

    //         this.props.history.push({
    //             pathname: '/login',
    //             isLogout:true                        
    //         })
        
    // }

    handeVideoClick = (item) => {        
        this.props.history.push({
            pathname: '/videoPlayer',            
            state: { docItem:item}
        })    
    }
        
    render() {
        const fontStyle = {
            width: "30px"
        }

        const cardStyle = {
        color: "#fff",
        backgroundColor: "#3C83AE",
        fontFamily: "Arial",      
        width:'100%',
        };

        const noContentStyle = {
            color: '#fff',
            textAlign: 'center'
        }
        const logBtnStyle = {            
            fontSize:'14px',
            fontFamily:'Roboto',
            backgroundColor:'#146095',
            borderColor:'#146095'        
        }
        return (<>
            
            <div className="docs-lists" style={{marginTop:'5%'}}>
                {/* <div className="page-heading">
                    Mijn Training
                </div> */}
             {AuthService.userDetail != null ? (
                <div>
                     {/* <div className="logout-btn" style={{bottom:"15px"}}>
                        <button className="btn btn-primary" style={logBtnStyle} onClick={() => this.handleLogoutClick()}>
                            Logout
                        </button>
                    </div> */}
                    {
                    !this.state.isLoading ? (<div className={"item"}>
                    <Loader type="spinner-default" bgColor={"#FFFFFF"} size={60} />
                    </div>) : 
                        <>
                           
                        <div  className="container">                                                   
                        {this.state.minjFolders != null &&  this.state.minjFolders.length > 0 ? 
                            (
                                <div className="row content-lists">
                                    
                                        {
                                            this.state.minjFolders.map((item, key) => (
                                                
                                                <div className="col-3"  key={key}   value={key}>

                                                {(() => {
                                                        switch (item.contentType) {
                                                        case 'folder':
                                                            return (<Link to={`mijnFolderContents/`+item.folderId+`/`+item.folderName}>                                                             
                                                                        <Card style={cardStyle}>  
                                                                            <FontAwesomeIcon style={fontStyle}                   
                                                                            size="8x"
                                                                            icon={faFolder}
                                                                            />                                                                    
                                                                            {item.folderName}
                                                                        </Card>
                                                                    </Link>);
                                                                    break
                                                        case 'document':
                                                            return (<Card style={cardStyle} onClick={() => this.handleClick(item)}>
                                                                        <FontAwesomeIcon style={fontStyle}                   
                                                                            size="8x"
                                                                            icon={item.type === 'word' ? faFile : faFilePdf}
                                                                        />                                                                                       
                                                                        {item.title}                                                                                               
                                                                    </Card>);
                                                            break

                                                        case 'url':
                                                            return (
                                                                <a href={item.url} target="__blank">
                                                                    <Card style={cardStyle}>                                
                                                                        <FontAwesomeIcon style={fontStyle}                   
                                                                        size="8x"
                                                                        icon={faImage}
                                                                        />                                
                                                                        {item.title}
                                                                    </Card>
                                                                </a>
                                                            );
                                                            break

                                                        case 'video':
                                                            return (
                                                                <div onClick={() => this.handeVideoClick(item)}>
                                                                    <Card style={cardStyle}>
                                                                        <FontAwesomeIcon style={fontStyle}                   
                                                                        size="8x"
                                                                        icon={faVideo}
                                                                        />                                                               
                                                                    {item.title}    
                                                                </Card>
                                                            </div>
                                                            );
                                                            break

                                                        default:
                                                            return (<div>No content type available</div>);
                                                        }
                                                    })()}                                                   
                                                </div> 
                                            ))
                                        }
                                </div>
                                
                            ) : <div style={noContentStyle}>No contents found</div>
                        }
                        </div></>

                    }
                </div>                        
                
            ) 
             :            
            (<Redirect to={{ pathname: '/login'}} /> )}                             
                                                          
            </div>
        </>)
    };

}


export default MinjData ;
