import React from 'react';
// import firebase from '../constant/firebase';
// import firebase from '../constant/firebase';
import Services from './firebaseServices' ;

// const firestore = firebase.firestore();
class AuthService extends React.Component {
    userDetail = null;
    constructor(props) {
        super(props)        
    } 
    
    setAuthUser = () => {       
        if(sessionStorage.getItem('userDetail') !== null && sessionStorage.getItem('userDetail') !== '' ) {            
            let user = sessionStorage.getItem('userDetail') !== null ? JSON.parse(sessionStorage.getItem('userDetail')) : null ;
            // console.log('ooooo', user);return ;
            
            if( user !== null ) {
                user['userRoleId'] = '' ; 
                Services.getUserRoleId(user.role_highest.role_name).then(res => {             
                    user['userRoleId'] = res.userRoleId !== undefined && res.userRoleId !== null ? res.userRoleId : '' ;
                })    
            }                                                                    
            this.userDetail= user;                        
        }  else {
            this.userDetail = null;
        }
    }

    logout = ()=> {
        sessionStorage.setItem('userDetail', '')
        sessionStorage.removeItem('userDetail');
        sessionStorage.clear();
        this.userDetail = '' ;
        return true ;
    }
}


const authServices = new AuthService();
export default authServices;

